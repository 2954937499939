import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Loading from '@/components/widgets/Loading.vue'
import Paginate from "@/components/widgets/pagination.vue";
import moment from 'moment'

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    Loading,

    Paginate
  },
  data: function() {
    return {
      busy: false,
      date_start: null,
      date_end: null,
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'กรุณาระบุวันที่และกดปุ่มแสดงข้อมูลเพื่อค้นหาข้อมูล',
      fields: [
        { 
          key: 'index', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'created_at', 
          label: 'วันที่', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        // { 
        //   key: 'roll_detail.at_branch.name', 
        //   label: 'สาขา', 
        //   thClass: 'font-weight-bold text-xs-1/2',
        //   tdClass: 'td-top text-xs-1/2',
        //   sortable: true 
        // },
        // { 
        //   key: 'roll_detail.coil', 
        //   label: 'รหัสสินค้า', 
        //   thClass: 'font-weight-bold text-xs-1/2',
        //   tdClass: 'td-top text-xs-1/2',
        //   sortable: true 
        // },
        { 
          key: 'name', 
          label: 'สินค้า', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        // { 
        //   key: 'roll_detail.brand', 
        //   label: 'ยี่ห้อ', 
        //   thClass: 'font-weight-bold text-xs-1/2',
        //   tdClass: 'td-top text-xs-1/2',
        //   sortable: true 
        // },
        // { 
        //   key: 'roll_detail.size', 
        //   label: 'ความหนา                ', 
        //   thClass: 'font-weight-bold text-xs-1/2',
        //   tdClass: 'td-top text-xs-1/2',
        //   sortable: true 
        // },
        // { 
        //   key: 'roll_detail.color', 
        //   label: 'สี', 
        //   thClass: 'font-weight-bold text-xs-1/2',
        //   tdClass: 'td-top text-xs-1/2',
        //   sortable: true 
        // },
        // { 
        //   key: 'roll_detail.net_weight', 
        //   label: 'น้ำหนัก', 
        //   thClass: 'font-weight-bold text-xs-1/2',
        //   tdClass: 'td-top text-xs-1/2',
        //   sortable: true 
        // },
        // { 
        //   key: 'roll_detail.length', 
        //   label: 'จำนวน', 
        //   thClass: 'font-weight-bold text-xs-1/2',
        //   tdClass: 'td-top text-xs-1/2',
        //   sortable: true 
        // },
        { 
          key: 'description', 
          label: 'รายละเอียด', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'font-weight-bold text-black td-top text-xs-1/2',
        },
        { 
          key: 'position_before', 
          label: 'ตำแหน่ง (ก่อน)', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'font-weight-bold text-black td-top text-xs-1/2',
        },
        { 
          key: 'position_after', 
          label: 'ตำแหน่ง (หลัง)', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'font-weight-bold text-black td-top text-xs-1/2',
        },
        { 
          key: 'creator.name', 
          label: 'ผู้จัดทำ', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'font-weight-bold text-black td-top text-xs-1/2',
        },
        // { 
        //   key: 'total_price', 
        //   label: 'ยอดเงิน ฿', 
        //   sortable: true,
        //   thClass: 'font-weight-bold text-xs-1/2',
        //   tdClass: 'font-weight-bold text-black td-top text-xs-1/2',
        //   class: 'text-right' 
        // },
        // { 
        //   key: 'document.document_no', 
        //   label: 'เลขที่ใบสำคัญ', 
        //   sortable: true,
        //   thClass: 'font-weight-bold text-xs-1/2',
        //   tdClass: 'text-black td-top text-xs-1/2',
        //   class: 'text-right' 
        // }
      ],
      items: [

      ],
      form: {
        keyword: '',
        start_date: null,
        end_date: null
      },
      seller_list: [],
      sum: 0,
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    async onInitData(currentPage) {
      try {
        const item = this.items
        this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'

        const page = currentPage ? currentPage : 1
        const res = await this.$axios.get(
          this.$host + "/api/v1/report/coilstatus?page=" + page + "&keyword=" + this.form.keyword + "&keyword_startdate="+(this.form.start_date != null ? moment(this.form.start_date).format('YYYY-MM-DD') : '') +"&keyword_enddate="+ (this.form.end_date != null ? moment(this.form.end_date).format('YYYY-MM-DD') : ''),
                )
        
        const data = res.data.data

        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.items = data.data
        this.currentData = data
        this.sum = data.sum

      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async exportExcelReport() {
      try {
        this.loading = true;
        
        const response = await this.$axios.get(
          this.$host + "/api/v1/report/excel/coilstatus?keyword=" + this.form.keyword + "&keyword_startdate="+(this.form.start_date != null ? moment(this.form.start_date).format('YYYY-MM-DD') : '') +"&keyword_enddate="+ (this.form.end_date != null ? moment(this.form.end_date).format('YYYY-MM-DD') : ''),
          { responseType: 'blob' }
        );
    
        const url = URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Roll status report.xls`);
        
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Cleanup after click
    
      } catch (error) {
        this.onExceptionHandler(error?.response?.data?.message || "An error occurred while downloading the file.");
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted(){
  }
}